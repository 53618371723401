<template>
  <div id="procedure-module">
    <b-row>
      <b-col cols="12">
        <p class="form-title mb-3">
          <span v-if="guide == 'SP/SADT'">Dados da Execução</span>
          <span v-else-if="guide == 'fee'">Procedimentos realizados</span>
          <span v-else-if="guide == 'hospitalization_summary'">Dados dos Procedimentos e Exames Realizados / Identificação da Equipe</span>
        </p>
      </b-col>

      <b-row v-if="form.items && form.items.length">
        <b-col
          cols="12"
          v-for="(tissGuideItem, index) of form.items" :key="index"
        >          
          <div class="procedure-wrapper">
            <div class="procedure-title">
              Cód. proced.
              <span v-if="tissGuideItem.procedure?.code && tissGuideItem.procedure.name">{{ `${tissGuideItem?.procedure?.code} - ${tissGuideItem?.procedure?.name}` }}</span>
              <span v-else-if="tissGuideItem?.procedure?.procedure && tissGuideItem?.procedure?.procedure.name">{{ `${tissGuideItem?.procedure?.procedure?.code} - ${tissGuideItem?.procedure?.procedure?.name}` }}</span>
              <span v-else-if="tissGuideItem?.item?.procedure && tissGuideItem?.item?.procedure.name">{{ `${tissGuideItem?.item?.procedure?.code} - ${tissGuideItem?.item?.procedure?.name}` }}</span>
              <span v-else>{{ `${tissGuideItem.item?.code} - ${tissGuideItem.item?.name}` }}</span>
            </div>
            <b-table-simple fixed class="table" responsive borderless>
              <thead>
                <tr>
                  <th><Ellipsis>Data</Ellipsis></th>
                  <th><Ellipsis>Hora inicial</Ellipsis></th>
                  <th><Ellipsis>Hora final</Ellipsis></th>
                  <th><Ellipsis>Tabela</Ellipsis></th>
                  <th><Ellipsis>Cód. proced.</Ellipsis></th>
                  <th>Descrição</th>
                  <th>Qtde.</th>
                  <th>Via</th>
                  <th>Tec</th>
                  <th v-if="tissGuideItem.id && showOnlending()">Repasse</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><Ellipsis>{{ parseDate(tissGuideItem.execution_date) }}</Ellipsis></td>
                  <td>{{ formatTime(tissGuideItem.start_time) }}</td>
                  <td>{{ formatTime(tissGuideItem.end_time) }}</td>
                  <td class="text-left">
                    <Ellipsis v-if="parseInt(tissGuideItem.table_name) === 22">TUSS - Procedimentos e eventos em saude</Ellipsis>
                    <Ellipsis v-else-if="parseInt(tissGuideItem.table_name) === 0">TUSS - Tabela própria das operadoras</Ellipsis>
                    <Ellipsis v-else>Tabela {{ tissGuideItem.table_name }}</Ellipsis>
                  </td>
                  <td>{{ tissGuideItem?.item ? tissGuideItem?.item?.code : tissGuideItem?.procedure?.procedure?.code }}</td>
                  <td>
                    <Ellipsis>{{ tissGuideItem.description }}</Ellipsis>
                  </td>
                  <td>{{ tissGuideItem.quantity }}</td>
                  <td><Ellipsis>{{ getPathwayLabel(tissGuideItem.pathway) || '-' }}</Ellipsis></td>
                  <td>{{ getTechniqueLabel(tissGuideItem.technique) || '-' }}</td>
                  <td v-if="tissGuideItem.id && showOnlending()">
                    <div v-if="tissGuideItem.onlending_rules">
                      <TaskAlt class="task-alt-icon" v-b-tooltip.hover title="Procedimento com regra de repasse definida" />
                    </div>
                    <div v-else>
                      <Error 
                        class="error-icon" 
                        v-b-tooltip.hover title="Procedimento sem regra de repasse definida" 
                      />
                    </div>
                  </td>
                  <td class="text-right d-flex">
                    <Edit
                      v-if="!readonly"
                      class="edit"
                      @click="editProcedure(tissGuideItem, index)"
                    />
                    <Delete
                      v-if="!readonly && verifyExistOngoingOnlending(tissGuideItem)"
                      @click="deleteProcedure(index)"
                      class="delete"
                    />
                  </td>
                </tr>
              </tbody>
            </b-table-simple>

            <div class="ml-2">
              <ProfessionalParticipationTableModule
                :participationPercentage="participationPercentage"
                :guide="guide"
                :grades="grades"
                :councils="councils"
                :professionalOptions="professionalOptions"
                :professionals="tissGuideItem.professionals"
                :beneficiariesList="beneficiariesList"
                :procedureValue="tissGuideItem.total_value"
              />
            </div>
          </div>
        </b-col>
      </b-row>

      <div class="col-12 text-center">
        <hr id="hr" />
        <b-button
          variant="link"
          :disabled="readonly"
          @click="addRealizedProcedures"
        >
          <div>Adicionar procedimento realizado</div>
        </b-button>
      </div>
      <div class="col-12">
        <hr id="hr" />
      </div>
    </b-row>

    <ProceduresFormModal
      :guide="guide"
      :form="form"
      :tissGuideItem="tissGuideItem"
      :settingsId="settingsId"
      :techniques="techniques"
      :invoicingTissSetting="invoicingTissSetting"
      :participationPercentage="participationPercentage"
      :specialCases="specialCases"
      :beneficiariesList="beneficiariesList"
      @addProfessional="addProfessional"
      @updateProfessional="updateProfessional"
      @deleteProfessional="deleteProfessional"
      @addOrUpdateTissGuideItem="$emit('addOrUpdateTissGuideItem', $event)"
      @onHide="onHide"
    />

  </div>
</template>

<script>
import {  showOnlending } from '@/utils/permissionsHelp';
import { parseNumberToMoney } from '@/utils/moneyHelper'

export default {
  name: 'RealizedProceduresModule',
  components: {
    Delete: () => import('@/assets/icons/delete.svg'),
    Edit: () => import('@/assets/icons/edit.svg'),
    ProfessionalParticipationTableModule: () => import('./ProfessionalParticipationTableModule'),
    ProceduresFormModal: () => import('../ProceduresFormModal'),
    Ellipsis: () => import('@/components/General/Ellipsis'),
    TaskAlt: () => import('@/assets/new-icons/task-alt.svg'),
    Error: () => import('@/assets/new-icons/error.svg'),
  },
  props: {
    tissGuideId: String,
    guide: String,
    settingsId: String,
    invoicingTissSetting: Object,
    participationPercentage: Object,
    beneficiariesList: Array,
    readonly: {
      type: Boolean,
      default: false
    },
    specialCases: Object,
    form: {
      type: Object,
      default: () => ({})
    }
  },
  async created() {
    await this.getProfessionals()
  },
  data() {
    return {
      professionalOptions: [],
      tissGuideItem: {},
      vias: [
        { label: 'Única (Bilateral)', value: '1' },
        { label: 'Mesma via', value: '2' },
        { label: 'Diferente vias', value: '3' }
      ],
      techniques: [
        { label: 'Convencional', value: '1' },
        { label: 'Vídeo', value: '2' },
        { label: 'Robótica', value: '3' }
      ],
      grades: require('@/components/Tiss/Data/grades.json'),
      councils: require('@/components/Tiss/Data/professionalCouncils.json'),
    }
  },
  methods: {
    showOnlending,
    parseDate(date) {
      return date && (this.moment(date).isValid()) ? this.moment(date).format('DD/MM/YYYY') : ''
    },
    formatTime(time) {
      return time && (this.moment(time, 'HH:mm:ss').isValid()) ? this.moment(time, 'HH:mm:ss').format('HH:mm') : ''
    },
    getFinalAmount(value) {
      if (!this.specialCases) return this.parseNumberToMoney(value)
      const { other_apartment: otherApartment, other_dhe: otherDhe, other_urgency: otherUrgency } =  this.specialCases
      if (this.form.apartment) {
        value = (otherApartment / 100) * value
      }
      if (this.form.dhe) {
        value += (otherDhe / 100) * value
      }
      if (this.form.urgency) {
        value += (otherUrgency / 100) * value
      }
      return this.parseNumberToMoney(value)
    },
    parseNumberToMoney,
    async getProfessionals() {
      if (!this.settingsId) return
      try {
        const { data } = await this.api.getInvoicingTissSettingProfessionals(this.settingsId);
        this.professionalOptions = data.map(item => {
          return {
            label: item.professional.name,
            value: item.professional.id
          }
        });
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    editProcedure(item, index) {
      this.tissGuideItem = {...item}
      this.tissGuideItem.index = index
      this.addRealizedProcedures()
    },
    async deleteProcedure(index) {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">Deseja realmente remover este procedimento?</div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
       }).then(res => {
        if (res.isConfirmed) {
          this.$emit('deleteTissGuideItem', index)
        }
      })
      
    },
    addRealizedProcedures() {
      this.$bvModal.show('realized_procedures_form_modal');
    },
    onHide() {
      this.tissGuideItem = {}
    },
    verifyExistOngoingOnlending(item){
      if (!item.professionals.some(el => el.id)) return true
      return !item.professionals.some(el => el.onlending_status && el.onlending_status !== "Em aberto")
    },
    getPathwayLabel(pathway) {
      return pathway?.label || this.vias.find(el => el.value === pathway)?.label
    },
    getTechniqueLabel(technique) {
      return technique?.label || this.techniques.find(el => el.value === technique)?.label
    },
    addProfessional(professional) {
      const professionals = Object.assign([], this.tissGuideItem.professionals ?? [])
      professionals.push(professional)
      this.tissGuideItem = {
        ...this.tissGuideItem,
        professionals
      }
    },
    updateProfessional({ index, professional }) {      
      const professionals = Object.assign([], this.tissGuideItem.professionals ?? [])
      professionals[index] = professional
      this.tissGuideItem = {
        ...this.tissGuideItem,
        professionals
      }
    },
    deleteProfessional({ name, index }) {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">Deseja realmente remover o profissonal <span>${name}</span>?</div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
       }).then(res => {
        if (res.isConfirmed) {
          const professionals = Object.assign([], this.tissGuideItem.professionals ?? [])
          professionals.splice(index, 1)
          this.tissGuideItem = {
            ...this.tissGuideItem,
            professionals
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/table.scss';

  .edit {
    width: 24px;
    height: 24px;
    stroke: var(--blue-500);
    cursor: pointer;
  }

  .delete {
    width: 24px;
    height: 24px;
    stroke: var(--red-500);
    cursor: pointer;
  }

  .procedure-title {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #1f3278;
    margin: 0;
    padding-bottom: 0.5rem;
  }
  .procedure-wrapper {
    padding:  1rem;
    border: 1px solid var(--neutral-200);
    border-radius: 4px;
    margin-bottom: 1rem;
  }
  .procedure-limit-alert {
    font-size: 0.6rem;
    color: var(--orange);
  }
  .task-alt-icon {
    width: 20px;
    fill: var(--states-success);
  }
  .error-icon {
    width: 20px;
    cursor: pointer;
    fill: var(--states-error);
  }

</style>
